<template>
  <section>
    <div class="ll-topbox">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Delivery Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'regular-pickup' }" >
          {{ $t('Regular Pickup') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active v-if="this.$route.params.id">
          {{ $t('Edit') }} {{ $t('Regular Pickup') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active v-else>
          {{ $t('Create') }} {{ $t('Regular Pickup') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'regular-pickup' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    </div>

    <!-- first floor -->
    <validation-observer ref="rules">
        <b-card class="ll-card" >
          <b-card-header>
            <b-card-title>{{ $t('Regular Pickup Schedule') }}</b-card-title>
            <div class="ll-required">* {{ $t('Required Fields') }}</div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('Customer')"
                  label-for="customer"
                >
                  <v-select
                  v-model="customer"
                  :options="customerList"
                  label="info"
                  @input="selCustomer"
                  placeholder="Select Customer"
                  ></v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                    <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Contact person"
                        rules="required"
                    >
                        <label class="ll-boldText">{{ $t('Contact person') }}</label>
                        <b-form-input v-model="info.contactName"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Contact Number"
                        rules="required"
                    >
                        <label class="ll-boldText">{{ $t('Contact number') }}</label>
                        <b-form-input v-model="info.contactPhone" type="number" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('')"
                  label-for="address"
                >
                <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Address') }}</label>
                    <b-form-input v-model="info.pickupAdd"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('')"
                  label-for="district"
                >
                <validation-provider
                    #default="{ errors }"
                    name="District"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('District') }}</label>
                    <b-form-input v-model="info.pickupDistrict"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('')"
                  label-for="area"
                >
                <validation-provider
                    #default="{ errors }"
                    name="Area"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Area') }}</label>
                    <v-select
                      :options="areaList"
                      label="codeDescription"
                      @input="selArea"
                      v-model="hkArea"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('')"
                  label-for="Sort Code"
                >
                <validation-provider
                    #default="{ errors }"
                    name="Sort Code"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Sort Code') }}</label>
                    <b-form-input v-model="info.sortCode"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('')"
                  label-for=""
                >
                <validation-provider
                    #default="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <label class="ll-boldText">{{ $t('Status') }}</label>
                    <v-select
                      :options="statusList"
                      label="name"
                      @input="selStatus"
                      v-model="status"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr style="margin: 2rem 1.5rem 1.5rem 1.5rem"/>
            <h4 style="padding: 1.5rem 0">{{ $t('Schedule') }}</h4>
            <b-row class="mt-1 ml-5">
              <div style="width:100px">Monday</div>
              <b-form-checkbox-group id="checkbox-group-1" v-model="selected1" @input="selM" name="flavour-1" class="ml-5">
                <b-form-checkbox value="A">AM</b-form-checkbox>
                <b-form-checkbox value="P">PM</b-form-checkbox>
              </b-form-checkbox-group>
            </b-row>
            <b-row class="mt-1 ml-5">
              <div style="width:100px">Tuesday</div>
              <b-form-checkbox-group id="checkbox-group-2" v-model="selected2" @input="selTu" name="flavour-2" class="ml-5">
                <b-form-checkbox value="A">AM</b-form-checkbox>
                <b-form-checkbox value="P">PM</b-form-checkbox>
              </b-form-checkbox-group>
            </b-row>
            <b-row class="mt-1 ml-5">
              <div style="width:100px">Wednesday</div>
              <b-form-checkbox-group id="checkbox-group-3" v-model="selected3" @input="selW" name="flavour-3" class="ml-5">
                <b-form-checkbox value="A">AM</b-form-checkbox>
                <b-form-checkbox value="P">PM</b-form-checkbox>
              </b-form-checkbox-group>
            </b-row>
            <b-row class="mt-1 ml-5">
              <div style="width:100px">Thursday</div>
              <b-form-checkbox-group id="checkbox-group-4" v-model="selected4" @input="selTh" name="flavour-4" class="ml-5">
                <b-form-checkbox value="A">AM</b-form-checkbox>
                <b-form-checkbox value="P">PM</b-form-checkbox>
              </b-form-checkbox-group>
            </b-row>
            <b-row class="mt-1 ml-5">
              <div style="width:100px">Friday</div>
              <b-form-checkbox-group id="checkbox-group-5" v-model="selected5" @input="selF" name="flavour-5" class="ml-5">
                <b-form-checkbox value="A">AM</b-form-checkbox>
                <b-form-checkbox value="P">PM</b-form-checkbox>
              </b-form-checkbox-group>
            </b-row>
            <div class="ll-subBtn">
              <b-button
                variant="info"
                @click.prevent="validationForm"
              >
                {{ $t('Submit') }}
              </b-button>
            </div>
          </b-card-body>
        </b-card>
    </validation-observer>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, BTab, BTabs,BFormSelect, BFormCheckbox, BFormRadio, BImg,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { debounce } from "@/libs/fun.js"
import permission from '@core/directives/permission/index.js'
export default {
  directives: {
    permission,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    flatPickr,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BTab,
    BTabs,
    BFormSelect,
    ToastificationContent,
    BFormCheckbox,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      isAdd: false,
      isDefault: true,
      areaList: [],
      info: {
        contactName: null, 
        contactPhone: null, 
        pickupAdd: null, 
        pickupCity: null, 
        pickupDistrict: null, 
        orgID: null, 
        companyName:null,
        sortCode: null,
        status:true
      },
      hkArea: '',
      customerList:[],
      customer:'',
      status:'ACTIVE',
      statusList:[{name:'INACTIVE',value:false},{name:'ACTIVE',value:true}],
      selected1:[],
      selected2:[],
      selected3:[],
      selected4:[],
      selected5:[],
      dateData:{
        "monday": "empty", 
        "tuesday": "empty", 
        "wednesday": "empty", 
        "thursday": "empty", 
        "friday": "empty"
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.queryArea()
    this.queryCustomer()
    let detail = this.$route.params
    if (detail&&detail.id) {
      this.$http.get('/regularPickup/getById?id='+detail.id).then(res => {
        let e = res.data.data
        this.info = e
        this.hkArea = e.pickupCity
        this.customer = e.orgID+' - '+e.companyName
        this.dateData = {
          "monday": e.monday, 
          "tuesday": e.tuesday, 
          "wednesday": e.wednesday, 
          "thursday": e.thursday, 
          "friday": e.friday 
        }
        if (e.monday) {
          this.selected1 = e.monday.split(',')
        }
        if (e.tuesday) {
          this.selected2 = e.tuesday.split(',')
        }
        if (e.wednesday) {
          this.selected3 = e.wednesday.split(',')
        }
        if (e.thursday) {
          this.selected4 = e.thursday.split(',')
        }
        if (e.friday) {
          this.selected5 = e.friday.split(',')
        }
      })
      
    }
  },
  mounted() {
    
  },
  methods: {
    selStatus(val){
      if (val) {
        this.info.status = val.value
      }
    },
    selM(val){
      let item
      if (val&&val.length>0) {
        if(val.length >= 2) val = val.filter(item => item != 'empty')
        item = {
          'monday':val.toString()
        }
      }else{
        item = {
          'monday':'empty'
        }
      }
       this.dateData=Object.assign(this.dateData, item)
    },
    selTu(val){
      let item
      if (val&&val.length>0) {
        if(val.length >= 2) val = val.filter(item => item != 'empty')
        item = {
          'tuesday':val.toString()
        }
      }else{
        item = {
          'tuesday':'empty'
        }
      }
      this.dateData=Object.assign(this.dateData, item)
    },
    selW(val){
      let item
      if (val&&val.length>0) {
       if(val.length >= 2) val = val.filter(item => item != 'empty')
        item = {
          'wednesday':val.toString()
        }
      }else{
        item = {
          'wednesday':'empty'
        }
      }
       this.dateData=Object.assign(this.dateData, item)
    },
    selTh(val){
      let item
      if (val&&val.length>0) {
       if(val.length >= 2) val = val.filter(item => item != 'empty')
        item = {
          'thursday':val.toString()
        }
      }else{
        item = {
          'thursday':'empty'
        }
      }
       this.dateData=Object.assign(this.dateData, item)
    },
    selF(val){
      let item
      if (val&&val.length>0) {
        if(val.length >= 2) val = val.filter(item => item != 'empty')
        item = {
          'friday':val.toString()
        }
      }else{
        item = {
          'friday':'empty'
        }
      }
       this.dateData=Object.assign(this.dateData, item)
    },
    queryCustomer() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
         this.customerList = res.data.data.map(res=>{
          let arr = {id:res.id,orgCode:res.orgCode,displayName:res.displayName, info:res.orgCode+' - '+res.displayName+' - '+res.keAccountNumber,name:res.name}
          return arr
        })

      })
    },
    selCustomer(d) {
      this.info.orgID = d.orgCode
      this.info.companyName = d.displayName
      this.$http.get('/lmd/pickAddress/find?orgCode='+d.orgCode).then( res => {
        if (res.data.data) {
          this.info.address = res.data.data.pickUpAddress
          this.hkArea = res.data.data.pickUpArea
          this.info.district = res.data.data.pickUpDistrict
          this.areaList.find(item=>{
            if (this.hkArea == item.codeDescription) {
              this.info.area = item.codeName
            }else{
              this.info.area = ''
            }
          })
        }else{
          this.info.pickupAdd = ''
          this.hkArea = ''
          this.info.pickupCity = ''
          this.info.pickupDistrict = ''
        }
        
      })
    },
    queryArea() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'hkArea'}}).then(res => {
        this.areaList = res.data.data
      })
    },
    selArea(d) {
      this.info.pickupCity = d ? d.codeName : null
      this.hkArea = d ? d.codeDescription : null
    },
    submit: debounce(function() {
      let date ={"date":this.dateData}
      let params = Object.assign(this.info,date)
      if (this.$route.params.id) {
        params.id = this.$route.params.id
        this.$http.post('regularPickup/update', params).then (res => {
          if (res.data.code === '200') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Request is submitted',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'regular-pickup' })
          }
        })
      }else{
        this.$http.post('regularPickup/create', params).then (res => {
          if (res.data.code === '200') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Request is submitted',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'regular-pickup' })
          }
        })
      }
      
    }, 1000),
    validationForm() {
      let i = this.dateData
      if ((!i.monday && !i.tuesday && !i.wednesday && !i.thursday && !i.friday) ||(i.monday=='empty' && i.tuesday=='empty' && i.wednesday=='empty' && i.thursday=='empty' && i.friday=='empty')) {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the Schedule',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
      }
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.submit()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 10px;
}
.ll-cutBox{
  padding: 10px;
  /* background-color: #e2f5fd; */
}
.ll-cutBox p{
  font-weight: bold;
}
.ll-cutBox li {
  padding: 5px 0;
}
.ll-radioBox {
  margin-bottom: 1rem;
}
.ll-radioBox1{
  margin-top: 10px;
}
.ll-radioBox .ll-radio{
  display: inline-block;
  /* width: 10%; */
}
.ll-select {
  display: inline-block;
  width: 90%;
}
.ll-card{
  margin-bottom: 10px;
  height: var(--height);
}
.ll-card1{
  height: var(--height1);
}
.ll-padding{
  padding-right: 0;
}
/* .ll-boldText{
  font-weight: bold;
} */
.ll-ul{
  padding-left: 1.5rem;
}
</style>
<style>
[dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, 
[dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, 
[dir] .vs--disabled .vs__open-indicator {
  background-color: unset !important;
}
</style>